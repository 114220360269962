import React from "react";
import "./projects.css";
import { forwardRef } from "react";
import Card from "../../UI/Card";
import git_icon from "../../../assets/images/github.webp";
import globe_icon from "../../../assets/images/globe-solid.webp";

const Projects = forwardRef((props, ref) => {
  const projects = [
    {
      title: "Common Core Projects",
      description:
        "Common Core projects from Campus 19 (part of The 42 Network).",
      url: "https://github.com/sekne18/42-Common-Core",
      logo: git_icon,
    },
    {
      title: "InvestmentTracker",
      description:
        "App keeps track of your crypto investments and it gives you fresh news, tips, strategies, technicals and more.",
      url: "https://github.com/sekne18/InvestmentTracker",
      logo: git_icon,
    },
    {
      title: "Cafe Mali Princ",
      description:
        "Designed and developed a modern website for a client in React.",
      url: "https://beautiful-palmier-a3e96b.netlify.app/",
      logo: globe_icon,
    },
    {
      title: "MealsApp",
      description:
        "App offers different meals and allows you to add them to the favorites",
      url: "https://github.com/sekne18/MealsApp",
      logo: git_icon,
    },
  ];

  return (
    <section className="projects pt-[30vh]" ref={ref}>
      <div className="text-center w-[90vw] sm:w-[70vw] md:w-[40vw] mx-auto mb-[20vh]">
        <p className="mb-5 font-bold text-4xl md:text-5xl">My Work</p>
        <p>
          I specialize in web and mobile development, but I’ve always been
          curious and eager to learn more about the recent trends
        </p>
      </div>
      <div className="mb-80 grid relative grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 mx-6 md:mx-24">
        {projects.map((proj, i) => (
          <Card
            key={i}
            title={proj.title}
            description={proj.description}
            url={proj.url}
            logo={proj.logo}
          />
        ))}
      </div>
    </section>
  );
});

export default Projects;
